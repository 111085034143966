import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0007 = () => (
  <Wrapper>
    <ColumnH1 label="通常の労働時間制（固定労働時間制度）を採用するメリットや注意点は？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0007.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        現在の日本での働き方は月曜日から金曜日まで同じ時間帯に働く働き方（固定労働時間制、以下通常の労働時間制）が多く採用されています。すなわち、
        <span>月曜日から金曜日まで働き、土日が休み</span>
        という勤務スタイルです。
        <br />
        しかし、通常の土日休みの勤務スタイルの他、フレックス、裁量労働のように、他の働き方も多く耳にします。
        <br />
        そして、コロナ禍により在宅勤務やサテライトオフィスでの勤務形態なども加わり、働き方は益々多様化しています。
        <br />
        <br />
        今回は月曜日から金曜日が勤務日でかつ、9時から18時が勤務時間と決められているような「通常の労働時間制」について解説してまいります。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">通常の労働時間制（固定労働時間制度）のメリット</a>
          </li>
          <li>
            <a href="#toc2">通常の労働時間制（固定労働時間制度）のデメリット</a>
          </li>
          <li>
            <a href="#toc3">
              通常の労働時間制（固定労働時間制度）を採用した場合の割増賃金
            </a>
          </li>
          <li>
            <a href="#toc4">
              労働時間の適正な把握のために使用者が講ずべき措置に関するガイドライン
            </a>
          </li>
          <li>
            <a href="#toc5">テレワークに固定労働時間制度を用いる場合の留意点</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>通常の労働時間制（固定労働時間制度）のメリット</h1>
      </div>
      <p>
        通常の労働時間制（以下、固定労働時間制度）は一般的に最も多く採用されていると考えがちですが、
        <span>統計上は変形労働時間制の方が多く採用</span>されています。
        <br />
        <br />
        固定労働時間制度のメリットを挙げるとすると、最もオーソドックスな労務管理となり、特定の週や日に限って本来働くべきとされる労働時間に差がないことから、
        <span>人事労務担当者が管理しやすい</span>という点はメリットと言えます。
      </p>
      <div id="toc2">
        <h1>通常の労働時間制（固定労働時間制度）のデメリット</h1>
      </div>
      <p>
        固定労働時間制度のデメリットとして、特定の週や日に限って長時間労働となることが予見できる場合を想定しましょう。
        <br />
        その日は法定労働時間を超えた場合は割増賃金の支払い義務が生じるという点です。1日8時間、1週間で40時間を超えた場合、固定労働時間制度を採用している場合は割増賃金支払いを回避する選択肢がありません。
        <br />
        すなわち、<span>割増賃金を支払うことが最低基準</span>
        になるということです。
      </p>
      <div id="toc3">
        <h1>通常の労働時間制（固定労働時間制度）を採用した場合の割増賃金</h1>
      </div>
      <p>
        固定労働時間制度と割増賃金の関係について確認しましょう。
        <br />
        法定労働時間を超えた場合は、<span>「時間外労働」</span>
        として扱われ、<span>通常の賃金に25％の割増分を付加</span>
        し、支払うことが最低基準となります。尚、時間外労働が引き続き深夜（22時～翌朝5時）にも行われた場合、
        <span>更に25％の割増</span>が最低基準となります。
        <br />
        <br />
        特に注意すべき点は労働基準法第41条2号に規定する
        <span>「管理監督者」（いわゆる管理職）</span>
        に該当する場合であっても深夜の時間帯に業務を行った場合は25％の深夜割増を支払わなければなりません。時間外労働は時間の長さに対して労働者保護の観点から割増賃金を支払うこととなりますが、深夜の場合も同じく労働者保護の観点はあるものの「特定の時間帯」に業務を行った場合に対して対価を支払うという考え方です。
        <br />
        尚、<span>2023年4月1日</span>
        からは中小企業に対しても月60時間超の時間外労働があった場合、50％の割増が必要となります（大企業は先行的に実施済）。
      </p>
      <div className="link-div">
        参考
        <p className="link-p">
          <Link
            className="p-list-link"
            to="https://www.mhlw.go.jp/new-info/kobetu/roudou/gyousei/kantoku/dl/091214-1_03.pdf"
            target="_blank"
            rel="noreferrer"
          >
            福岡労働局（中小企業該当の有無についての確認票）
          </Link>
        </p>
      </div>
      <Introduction002 />
      <p>
        また、本来働くべきとされる日が月曜日から金曜日の場合、土・日曜日に働いた場合はどのように整理すべきでしょうか。
        <br />
        <br />
        就業規則に「法定休日」として定めがある（例えば日曜日が法定休日）場合、
        <span>休日割増として割増賃金を支払う</span>
        必要があります。厳密には就業規則で法定休日を特定することまでは義務付けられていませんが、どの日が法定休日か法定外休日かがわかるようにしておくことが相応しいとされています。
        <br />
        尚、<span>休日割増は35％</span>
        であり、休日労働を深夜に行った場合、（35％+25％）60％の割増となります。
      </p>
      <div className="link-div">
        参考
        <p className="link-p">
          <Link
            className="p-list-link"
            to="https://jsite.mhlw.go.jp/tokyo-roudoukyoku/content/contents/000501860.pdf"
            target="_blank"
            rel="noreferrer"
          >
            厚生労働省 （改正労働基準法）
          </Link>
        </p>
      </div>
      <div id="toc4">
        <h1>
          労働時間の適正な把握のために使用者が講ずべき措置に関するガイドライン
        </h1>
      </div>
      <p>
        平成29年1月20日に策定された同ガイドラインとは、端的には使用者は労働者の労働時間を適正に把握しなければならないということです。
        <br />
        尚、労働時間とは<span>使用者の指揮命令下に置かれた時間</span>
        のことを言い、使用者の明示または黙示の指示により労働者が業務に従事する時間は労働時間と定義されます。
        <br />
        <br />
        労働時間には必ず<span>始業と終業時間</span>
        が存在します。その場合の確認方法としては、以下のいずれかでの確認を求められます。
        <br />
        <br />
        ・使用者による現認
        <br />
        ・タイムカードやPCの記録等の客観的な記録を基礎として確認
        <br />
        <br />
        例外として、自己申告制での労働時間の把握も明記されていますが、「労働時間」の定義についての研修、自己申告制の適正運用、自己申告制の労働時間が実態と合致しているかの検証を行い、必要に応じて補正を行うことが求められます。
      </p>
      <div id="toc5">
        <h1 className="read">
          テレワークに固定労働時間制度を用いる場合の留意点
        </h1>
      </div>
      <p>
        働き方改革、新型コロナウイルスの感染拡大防止の観点から
        <span>テレワークの必要性</span>が叫ばれています。
        <br />
        テレワークに限って対面業務でないことから労働基準法の規制から免れるということはなく、対面業務と同様に時間外労働の管理は必要です。
        <br />
        <br />
        また、固定労働時間制度を採用している場合、周囲に管理者がいないことと、生活と同じ空間で業務を行うという性質上、
        <span>気づいたときには時間外労働を行っていた</span>
        というケースも少なくありません。
        <br />
        よって、テレワークで固定労働時間制度を採用する場合、各従業員に対して対面業務以上に
        <span>時間管理を徹底する</span>
        よう呼びかける必要があります。これは、想定外の人件費を抑止することに留まらず、
        <span>労働者の健康被害を防止</span>する意味でも重要なことです。
        <br />
        <br />
        よって、これからテレワークを導入するという場合や、既に導入しているが適正に運用できているとは言い難い場合、外部のアウトソーシング会社や専門家の意見を参考にするなどして運用の適正化を図ることが（リスク回避の観点からも）重要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0007
