import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0007 from '../../../components/molecules/columnDetailInner0007'
import UsefulFunctionLayout021 from '../../../components/molecules/usefulFunctionLayout021'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0007 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        '働き方の定番？通常の労働時間制（固定労働時間制度）について詳しく解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="通常の労働時間制（固定労働時間制度）を採用するメリットや注意点は？"
        description="固定労働時間制は、働く曜日や時間帯が固定されるので先の予定が組みやすく、人事労務者も管理しやすい制度です。一方で良くない点や注意しなければならない点もあります。"
        ogUrl="https://kintaicloud.jp/column/details/C0007/"
        ogType="article"
        ogTitle="通常の労働時間制（固定労働時間制度）を採用するメリットや注意点は？"
        ogDescription="固定労働時間制は、働く曜日や時間帯が固定されるので先の予定が組みやすく、人事労務者も管理しやすい制度です。一方で良くない点や注意しなければならない点もあります。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0007.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0007 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout021 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0007

export const pageQuery = graphql`
  query C0007 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
